.App {
  text-align: center;
}

.tumbler-table {
  border: 1px solid #ccc;
  margin: 10px;
  padding: 10px;
}

.side {
  display: inline-block;
  width: 45%;
  vertical-align: top;
}

.color-picker {
  display: flex;
  flex-direction: column;
}

.color-picker div {
  margin-bottom: 10px;
}
